<template>
  <div v-if="worlds && worlds.length" class="worlds">
    <Grid :xl="3" :lg="3" :md="2" :sm="1" :scrollSm="false">
      <StoryWorldCard v-for="(world, index) of worlds" :key="index" class="grid-item" :world="world" />
    </Grid>

    <ion-button v-show="nextPageExists" class="clickable" @click="requestLoadMore">Load More</ion-button>
    <p class="no-data" v-show="!nextPageExists">End of list</p>
  </div>
  <div v-else-if="loading">
    <Grid :lg="3" :md="3" :sm="2" :scrollSm="false">
      <ion-skeleton-text v-for="(_, index) of [0, 1, 2, 3, 4]" :key="index" animated class="grid-item small-card" />
    </Grid>
  </div>
  <div v-else class="no-data">No Worlds</div>
</template>

<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import StoryWorldCard from '@/shared/components/storage/StoryWorldCard.vue';
import { WorldsInfo } from '@/shared/types/static-types';

const emit = defineEmits(['load']);

const props = defineProps({
  worlds: {
    type: Array as PropType<WorldsInfo[]>,
    required: true,
  },
  paging: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});

const nextPageExists = computed(() => {
  return !!props.paging?.next;
});

const requestLoadMore = () => {
  emit('load');
};
</script>

<style scoped lang="sass">
.worlds
  color: #214163
.small-card
  height: 150px
  border-radius: 8px
</style>
